import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home.jsx";
import PrivacyPolicyAfitik from "../pages/privacy/PrivacyPolicyAfitik.jsx";
import PrivacyPolicyWaterMark from "../pages/privacy/PrivacyPolicyWaterMark.jsx";
import NotFound from "../pages/NotFound.jsx";

export default function MainRoutes() {
    return (
        <Routes>
            {/* <Route path="/index.html" element={<Home />} /> */}
            <Route path="/" element={<Home backLink="/" />} />
            <Route path="/privacy/PrivacyPolicyAfitik.html" element={<PrivacyPolicyAfitik />} />
            <Route path="/privacy/PrivacyPolicyWaterMark.html" element={<PrivacyPolicyWaterMark />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
import React, { useState } from 'react';
import Skeleton from './Skeleton.jsx';

/**
 * A component that displays an image with a loading skeleton while the image is loading.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.src - The source URL of the image to be displayed.
 * @param {string} props.alt - The alt text for the image.
 * @param {string} [props.width] - The width of the container. Default is '100%'.
 * @param {string} [props.height] - The height of the container. Default is '20px'.
 * @param {Object} [props.style] - Additional inline styles to be applied to the container.
 * @param {Function} [props.onClick] - Click event handler for the container.
 * @param {Object} [props.otherProps] - Any other props to be passed to the container element.
 * 
 * @example
 * <ImageWithLoader
 *   src="https://example.com/image.jpg"
 *   alt="Example Image"
 *   width="300px"
 *   height="200px"
 *   onClick={() => console.log('Container clicked')}
 * />
 */
export default function ImageWithLoader({ src, alt, width, height, ...props }) {
    const [isLoading, setIsLoading] = useState(true);

    // Function to change loading state when image has loaded
    const handleLoad = () => {
        setIsLoading(false);
    };

    return (
        <div
            style={{ width, height, position: 'relative', overflow: 'hidden' }}
            {...props}
        >
            {isLoading && <Skeleton width={width} height={height} borderRadius="8px" />}
            <img
                src={src}
                alt={alt}
                onLoad={handleLoad}
                style={{
                    width: width,
                    height: height,
                    objectFit: 'cover',
                    display: isLoading ? 'none' : 'block'
                }}
            />
        </div>
    );
}

import React from 'react';

const skeletonStyle = {
    background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
    backgroundSize: '200% 100%',
    animation: 'loading 1.5s infinite',
    borderRadius: '4px',
};

export default function Skeleton({ width, height, borderRadius, margin }) {
    return (
        <div
            style={{
                ...skeletonStyle,
                width: width || '100%',
                height: height || '20px',
                borderRadius: borderRadius || '4px',
                margin: margin || '0',
            }}
        />
    );
};

const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
  @keyframes loading {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
`, styleSheet.cssRules.length);

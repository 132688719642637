import React from 'react';
import PropTypes from 'prop-types';
import ImageWithLoader from './ImageWithLoader.jsx';
import styles from '../assets/styles/app-card.module.css';
import { FaGlobe, FaDownload } from 'react-icons/fa'; // استيراد الأيقونات من react-icons

/**
 * مكون يعرض بطاقة تطبيق مع اسم وصورة ومعلومات وروابط
 *
 * @param {Object} props - خصائص المكون
 * @param {string} props.appName - اسم التطبيق
 * @param {string} props.imageSrc - مصدر صورة التطبيق
 * @param {string} props.imageAlt - نص بديل للصورة
 * @param {string} props.description - وصف التطبيق
 * @param {string} [props.websiteUrl] - رابط موقع التطبيق (اختياري)
 * @param {string} [props.downloadUrl] - رابط تنزيل التطبيق (اختياري)
 * @param {string} [props.className] - أسماء فئة CSS إضافية (اختياري)
 *
 * @returns {JSX.Element} - مكون بطاقة التطبيق
 */
export default function AppCard({ appName, imageSrc, imageAlt, description, websiteUrl, downloadUrl, className = '', height = "250px" }) {
    return (
        <div className={`${styles.appCard} ${className}`}>
            <div className={styles.appCardHeader}>
                <h2>{appName}</h2>
            </div>
            <div className={styles.appCardImage}>
                <ImageWithLoader
                    src={imageSrc}
                    alt={imageAlt}
                    width="100%"
                    height={height}
                    style={{ borderRadius: '8px' }} // تنسيق الصورة
                />
            </div>
            <div className={styles.appCardBody}>
                <p>{description}</p>
            </div>
            <div className={styles.appCardFooter}>
                {websiteUrl && (
                    <a href={websiteUrl} target="_blank" rel="noopener noreferrer" className={styles.appCardLink}>
                        <FaGlobe size={20} />
                    </a>
                )}
                {downloadUrl && (
                    <a href={downloadUrl} target="_blank" rel="noopener noreferrer" className={styles.appCardLink}>
                        <FaDownload size={20} />
                    </a>
                )}
            </div>
        </div>
    );
}

AppCard.propTypes = {
    appName: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    websiteUrl: PropTypes.string,
    downloadUrl: PropTypes.string,
    className: PropTypes.string,
};

import React, { createContext, useState, useContext, useEffect } from 'react';

// إنشاء سياق عام
const AppContext = createContext();

// إنشاء مزود السياق
export function AppProvider({ children }) {
    // محاولة قراءة السمة المخزنة من localStorage أو تعيينها إلى "light" كقيمة افتراضية
    const storedTheme = localStorage.getItem('theme') || 'light';

    const [isMuted, setIsMuted] = useState(false); // حالة الصوت
    const [currentTheme, setCurrentTheme] = useState(storedTheme); // حالة السمة
    const [backLink, setBackLink] = useState(null); // زر الرجوع

    const toggleMute = () => setIsMuted(!isMuted);

    // دالة لتبديل السمة وتخزينها في localStorage
    const toggleTheme = () => {
        const newTheme = currentTheme === 'light' ? 'dark' : 'light';
        setCurrentTheme(newTheme);
        localStorage.setItem('theme', newTheme); // تخزين السمة في localStorage
    };

    // تعيين السمة عند تغييرها
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', currentTheme);
    }, [currentTheme]);

    return (
        <AppContext.Provider value={{
            isMuted,
            toggleMute,
            currentTheme,
            toggleTheme,
            backLink,
            setBackLink,
        }}>
            {children}
        </AppContext.Provider>
    );
}

// استخدام السياق
export function useAppContext() {
    return useContext(AppContext);
}

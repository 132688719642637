import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext.jsx';
import SetPageMetadata from "../../components/SetPageMetadata.jsx";
import ScrollToTop from "../../components/ScrollToTop.jsx";
import useSound from '../../hooks/useSound.js';
import styles from '../../assets/styles/PrivacyPolicy.module.css';

const PrivacyPolicyWaterMark = () => {
    const {
        setBackLink,
        isMuted,
    } = useAppContext();
    const playClickSound = useSound('click');
    const [lang, setLang] = useState('ar');

    const switchLang = (selectedLang) => {
        setLang(selectedLang);
    };

    useEffect(() => {
        setBackLink("/");
    }, [setBackLink]);

    const currentUrl = window.location.origin + window.location.pathname;
    const pageMetadata = {
        title: "سياسة الخصوصية - WaterMark",
        description: "تعرف على كيفية جمع واستخدام وحماية بياناتك عند استخدامك لتطبيق WaterMark، الذي يضيف علامة مائية على الصور.",
        keywords: "سياسة الخصوصية, WaterMark, حماية البيانات, خصوصية المستخدم, علامة مائية, صور",
        canonicalUrl: currentUrl,
        contentLanguage: "ar",
        author: "WaterMark",
        structuredData: {
            "@context": "https://schema.org",
            "@type": "MobileApplication",
            "name": "WaterMark",
            "description": "تطبيق WaterMark يضيف علامة مائية على الصور فقط.",
            "operatingSystem": "Android",
            "applicationCategory": "Productivity",
            "url": currentUrl,
            "logo": {
                "@type": "ImageObject",
                "url": `${window.location.origin}/logo512.png`,
                "width": 512,
                "height": 512
            },
        }
    };

    return (
        <>
            <SetPageMetadata {...pageMetadata} />
            <ScrollToTop />
            <div className={styles.container}>
                <div className={styles.langSwitch}>
                    <button onClick={() => {
                        switchLang('ar')
                        if (!isMuted) playClickSound();
                    }} >العربية</button>
                    <button onClick={() => {
                        switchLang('en')
                        if (!isMuted) playClickSound();
                    }}>English</button>
                </div>

                {lang === 'ar' ? (
                    <div className={`${styles.section} ${styles.rtl}`}>
                        <h1>سياسة الخصوصية</h1>
                        <p><strong>1. مقدمة</strong></p>
                        <p>نحن في تطبيق <strong>WaterMark</strong> ملتزمون بحماية خصوصيتك وبياناتك الشخصية. توضح هذه السياسة كيفية جمع واستخدام وحماية المعلومات التي تقدمها عند استخدامك لتطبيقنا.</p>
                        <p><strong>2. المعلومات التي نجمعها</strong></p>
                        <p>نقوم بطلب صلاحية وصول للصور فقط، ولا نقوم بتخزين أي بيانات على الخادم. جميع البيانات تُعالج محلياً فقط على جهازك.</p>
                        <p><strong>3. كيفية استخدام المعلومات</strong></p>
                        <p>تتم معالجة الصور محلياً فقط لإضافة العلامة المائية وفقاً لاختياراتك.</p>
                        <p><strong>4. مشاركة المعلومات</strong></p>
                        <p>لن نقوم بمشاركة أي من بياناتك الشخصية مع أي أطراف ثالثة.</p>
                        <p><strong>5. حماية المعلومات</strong></p>
                        <p>نحن نحرص على الحفاظ على أمان بياناتك وعدم مشاركتها أو تخزينها.</p>
                        <p><strong>6. تغييرات على سياسة الخصوصية</strong></p>
                        <p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإعلامك بأي تغييرات عن طريق نشر السياسة المحدثة على هذه الصفحة.</p>
                        <p><strong>7. الاتصال بنا</strong></p>
                        <p>إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا على:</p>
                        <p>البريد الإلكتروني: <a href="mailto:rn0x.me@gmail.com">rn0x.me@gmail.com</a></p>
                        <p>الموقع: <a href="https://i8x.net" target="_blank">i8x.net</a></p>
                    </div>
                ) : (
                    <div className={`${styles.section} ${styles.ltr}`}>
                        <h1>Privacy Policy</h1>
                        <p><strong>1. Introduction</strong></p>
                        <p>At <strong>WaterMark</strong>, we are committed to protecting your privacy and personal data. This policy explains how we collect, use, and safeguard the information you provide when using our app.</p>
                        <p><strong>2. Information We Collect</strong></p>
                        <p>We request permission to access photos only, and we do not store any data on our servers. All data is processed locally on your device.</p>
                        <p><strong>3. How We Use Your Information</strong></p>
                        <p>Photos are processed locally to add watermarks according to your preferences.</p>
                        <p><strong>4. Sharing Your Information</strong></p>
                        <p>We will not share any of your personal data with third parties.</p>
                        <p><strong>5. Protecting Your Information</strong></p>
                        <p>We ensure the security of your data and do not share or store it.</p>
                        <p><strong>6. Changes to the Privacy Policy</strong></p>
                        <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the updated policy on this page.</p>
                        <p><strong>7. Contact Us</strong></p>
                        <p>If you have any questions about this privacy policy, you can contact us at:</p>
                        <p>Email: <a href="mailto:rn0x.me@gmail.com">rn0x.me@gmail.com</a></p>
                        <p>Website: <a href="https://i8x.net" target="_blank">i8x.net</a></p>
                    </div>
                )}
            </div>
        </>
    );
};

export default PrivacyPolicyWaterMark;

import React, { useEffect } from 'react';
import { FaGithub } from "react-icons/fa";
import SetPageMetadata from "../components/SetPageMetadata.jsx";
import ScrollToTop from "../components/ScrollToTop.jsx";
import AppCard from "../components/AppCard.jsx";
import { useAppContext } from '../contexts/AppContext.jsx';
import styles from '../assets/styles/home.module.css';
import storeApps from '../assets/json/storeApps.json'

export default function Home() {
  const { backLink, setBackLink, currentTheme } = useAppContext();

  useEffect(() => {
    setBackLink(null);
  }, [setBackLink]);

  const currentUrl = window.location.origin + window.location.pathname;
  const pageMetadata = {
    title: "آي إيت إكس - i8x",
    description: "آي إيت إكس (i8x) هو موقع متخصص في تقديم البرمجيات والخدمات التقنية المبتكرة، بما في ذلك تطبيقات الويب والتصميم الرقمي وحلول التكنولوجيا المتقدمة.",
    keywords: "آي إيت إكس, i8x, برمجيات, تطبيقات, تصميم, تكنولوجيا, حلول تقنية",
    ogImage: `${window.location.origin}/home.jpg`,
    canonicalUrl: currentUrl,
    contentLanguage: "ar",
    author: "i8x",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "آي إيت إكس - i8x",
      "description": "آي إيت إكس (i8x) هو موقع يقدم حلول برمجية متكاملة، بما في ذلك تطبيقات الويب، التصميم الرقمي، والخدمات التقنية المتقدمة.",
      "url": "https://i8x.net",
      "logo": {
        "@type": "ImageObject",
        "url": `${window.location.origin}/logo512.png`,
        "width": 512,
        "height": 512
      },
    }
  };

  const appsMenu = storeApps.map((e) => {
    return (
      <div key={e.id}>
        <AppCard
          appName={e.name}
          imageSrc={e.image}
          imageAlt={e.name}
          description={e.description}
          websiteUrl={e.websiteUrl}
          downloadUrl={e.downloadUrl}
        />
      </div>
    )
  });


  return (
    <>
      <SetPageMetadata {...pageMetadata} />
      <ScrollToTop />
      <div className={styles.MenuApp}>
        {appsMenu}
      </div>

      <div className={styles.gitContainer}>
        <a href="https://github.com/rn0x" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
      </div>

      <small style={{ display: "block", textAlign: 'center', marginBottom: "30px", marginTop: "0px" }}>
        i8x.net
      </small>

    </>
  );
}

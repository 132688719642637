// src/hooks/useSound.js
import { useCallback } from 'react';

const sounds = {
    click: require('../assets/sounds/click.mp3'),
    clickCorrect: require('../assets/sounds/click_correct.mp3'),
    clickIncorrect: require('../assets/sounds/click_incorrect.mp3'),
};

const useSound = (soundType) => {
    const playSound = useCallback(() => {
        if (soundType in sounds) {
            const audio = new Audio(sounds[soundType]);
            audio.play();
        }
    }, [soundType]);

    return playSound;
};

export default useSound;

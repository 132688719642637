// App.jsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MainRoutes from './routes/MainRoutes.jsx';
import Header from './components/Header.jsx';
import { AppProvider } from './contexts/AppContext.jsx';

export default function App() {
    return (
        <Router>
            <HelmetProvider>
                <AppProvider>
                    <Header />
                    <div id="App">
                        <MainRoutes />
                    </div>
                </AppProvider>
            </HelmetProvider>
        </Router>
    );
}

import React, { useEffect } from 'react'
import { useAppContext } from '../contexts/AppContext.jsx';

export default function NotFound() {

    const { setBackLink } = useAppContext();
    useEffect(() => {
        setBackLink("/");
    }, [setBackLink]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // اجعل العنصر يمتد على كامل ارتفاع الصفحة
            textAlign: 'center', // لتوسيط النص داخل الحاوية
            fontSize: '24px' // حجم الخط، يمكنك تعديله حسب الحاجة
        }}>
            الصفحة غير موجودة
        </div>
    )
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { useAppContext } from '../contexts/AppContext.jsx';
import styles from '../assets/styles/header.module.css';
import useSound from '../hooks/useSound.js';

export default function Header() {
    const {
        isMuted,
        toggleMute,
        currentTheme,
        toggleTheme,
        backLink,
    } = useAppContext();

    const playClickSound = useSound('click');

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', currentTheme);
    }, [currentTheme]);

    const handleThemeToggle = () => {
        toggleTheme(); // استخدام دالة السياق لتبديل السمة
        if (!isMuted) {
            playClickSound(); // تشغيل الصوت إذا لم يكن الصوت مكتوم
        }
    };

    return (
        <div className={styles.Header} id='Header'>
            <div className={styles.BoxHeader}>
                {backLink && (
                    <Link to={backLink} className={styles.backButton} onClick={() => { if (!isMuted) playClickSound(); }}>
                        <FaArrowRight />
                    </Link>
                )}

                <Link to="/" className={styles.title} onClick={() => { if (!isMuted) playClickSound(); }}>
                    آي إيت إكس
                </Link>

                <div className={styles.box_left_but}>
                    <button onClick={handleThemeToggle} className={styles.toggleButton}>
                        {currentTheme === 'light' ? 'نهاري' : 'ليلي'}
                    </button>
                    {isMuted ? (
                        <FaVolumeMute onClick={toggleMute} className={styles.muteButton} />
                    ) : (
                        <FaVolumeUp onClick={toggleMute} className={styles.muteButton} />
                    )}
                </div>
            </div>
        </div>
    );
}
